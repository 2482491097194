import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DownloadMaterial, LiveMaterial, MaterialDialogInput, VideoCategoryEx, VideoMaterial } from '../../model/material-dialog.model';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConfigService } from '../../../../core/config/config.service';
import { categoryPathToCategory, pathToCategories } from '../../../../core/util/category.util';
import { UserService } from '../../../../core/user-service/user.service';
import { forkJoin, Subscription } from 'rxjs';
import { CategoriesService } from '../../../../core/categories-service/categories.service';
import { LiveService } from '../../../../core/live-service/live.service';
import { VideoCategory } from '../../../../core/interfaces/video-category';
import { ViewLiveRecordingDialogComponent } from '../../../dialogs/view-live-recording-dialog/view-live-recording-dialog.component';
import { Meeting } from '../../../../core/interfaces/meeting';
import { User } from '../../../../core/interfaces/user';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-material-dialog',
  templateUrl: './material-dialog.component.html',
  styleUrls: ['./material-dialog.component.scss'],
})
export class MaterialDialogComponent implements OnInit, OnDestroy {
  public state: ComponentState = COMPONENT_STATE.LOADING;
  public readonly subjectLabel: string;
  public videos?: Array<VideoCategoryEx>;
  public lives?: Array<Meeting>;
  public downloadable?: Array<string>;
  public completedMaterial = 0;
  public totalMaterial = 0;
  public totalPercentage = 0;
  private sub?: Subscription;
  private readonly archiveLabels: { lables: Array<string>; whitelist: boolean } | undefined;
  private readonly categoryPath: string;
  private userLabels: Array<string> = [];

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: MaterialDialogInput,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) bottomSheetData: MaterialDialogInput,
    @Optional() private dialogRef: MatDialogRef<MaterialDialogComponent>,
    @Optional() private bottomSheetRef: MatBottomSheetRef<MaterialDialogComponent>,
    configService: ConfigService,
    private userService: UserService,
    private categoriesService: CategoriesService,
    private liveService: LiveService,
    private dialog: MatDialog
  ) {
    this.categoryPath = (dialogData || bottomSheetData).categoryPath;
    this.archiveLabels = configService.archiveLabels;

    const categories = pathToCategories(this.categoryPath);
    this.subjectLabel = categoryPathToCategory(categories[2] || categories[1] || categories[0]);
  }

  ngOnInit(): void {
    this.createContent();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  public materialAction(action: VideoMaterial | LiveMaterial | DownloadMaterial): void {
    if (action.type === 'video') {
      this.showVideo(action.material);
    }
    if (action.type === 'live') {
      this.showLive(action.material);
    }
    if (action.type === 'download') {
      this.downloadFile(action.material);
    }
  }

  private createContent(): void {
    this.state = COMPONENT_STATE.LOADING;
    this.videos = new Array<VideoCategoryEx>(5).fill({ percentageView: 0 } as VideoCategoryEx);

    this.sub = forkJoin([
      this.userService.getFuturaUser(),
      this.userService.getLabel(),
      this.categoriesService.getVideos(),
      this.liveService.getAll(),
    ]).subscribe({
      next: ([user, userLabels, videos, lives]) => {
        this.userLabels = userLabels;
        this.totalMaterial += this.handleVideos(videos, user);
        // todo: enable lives ->  this.totalMaterial += this.handleLive(lives);

        this.totalPercentage = (100 * this.completedMaterial) / (this.totalMaterial || 1);

        this.state = COMPONENT_STATE.NONE;

        if (!this.totalMaterial) {
          this.state = COMPONENT_STATE.GHOST;
        }
      },
      error: () => (this.state = COMPONENT_STATE.GHOST),
    });
  }

  private handleVideos(videos: Array<VideoCategory>, user: User): number {
    const vidioMeta: Record<string, number> = {};
    user?.content?.video_list?.forEach(v => (vidioMeta[v.live_id] = +((v.time / v.total) * 100).toFixed(2)));

    this.videos = videos
      .filter(v => v.category_path.startsWith(this.categoryPath))
      .map(video => {
        const percentageView = vidioMeta[video.video_uuid] || 0;
        if (percentageView === 100) {
          this.completedMaterial++;
        }

        const [h, m, s] = new Date(video.duration * 1000).toISOString().slice(11, 19).split(':');

        const durationS = +s;
        const durationM = +m;
        const durationH = +h;

        return { ...video, percentageView, durationM, durationH, durationS };
      });

    return this.videos.length;
  }

  private handleLive(lives: Array<Meeting>): number {
    this.lives = lives.filter(live => live.content.categories.some(c => c.startsWith(this.categoryPath)));
    this.downloadable = [];
    this.lives.map(l => l.content.files).forEach(d => this.downloadable?.push(...d));
    return this.lives.length + this.downloadable.length;
  }

  private showVideo(video: VideoCategory): void {
    if (!this.canView()) {
      this.userService.missingLicenseDialog();
      return;
    }
    this.dialog.open(ViewLiveRecordingDialogComponent, {
      data: { video },
      width: '90vw',
      height: '70vh',
      panelClass: 'fut-mat-dialog-transparent',
    });
    this.hideDialog();
  }

  private showLive(live: Meeting): void {
    this.dialog.open(ViewLiveRecordingDialogComponent, {
      data: { live },
      width: '90vw',
      height: '70vh',
      panelClass: 'fut-mat-dialog-transparent',
    });
    this.hideDialog();
  }

  private downloadFile(download: string): void {
    if (!this.canView()) {
      this.userService.missingLicenseDialog();
      return;
    }
    this.liveService.downloadSlide(download);
  }

  private hideDialog(): void {
    this.dialogRef?.close();
    this.bottomSheetRef?.dismiss();
  }

  private canView(): boolean {
    if (!this.archiveLabels) {
      return false;
    }
    return this.userService.checkLables(this.userLabels || [], this.archiveLabels.lables || [], this.archiveLabels.whitelist);
  }
}
