import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CardStatus } from './collapsable-card.model';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { MatIcon } from '@angular/material/icon';
import { COMPONENT_STATE, ComponentState, GhostStateFragmentDirective } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-collapsable-card',
  templateUrl: './collapsable-card.component.html',
  styleUrls: ['./collapsable-card.component.scss'],
  standalone: true,
  animations: [
    trigger('accordion', [
      transition(':enter', [
        style({
          height: 0,
          'margin-top': 0,
          overflow: 'hidden',
        }),
        animate(
          150,
          style({
            height: '*',
            'margin-top': '*',
            overflow: '*',
          })
        ),
      ]),
      transition(':leave', [
        animate(
          150,
          style({
            height: 0,
            'margin-top': 0,
            overflow: 'hidden',
          })
        ),
      ]),
    ]),
  ],
  imports: [NgClass, TranslocoModule, NgTemplateOutlet, MatIcon, GhostStateFragmentDirective],
})
export class CollapsableCardComponent {
  @Input() public state: ComponentState = COMPONENT_STATE.NONE;

  @Input() public cardStatus: CardStatus = 'close';
  @Input() public cardTitle?: string;
  @Input() public leftContent?: TemplateRef<unknown>;

  @Output() public readonly cardStatusChange = new EventEmitter<CardStatus>();

  public toggleState(event?: Event): void {
    event?.stopImmediatePropagation();
    this.cardStatus = this.cardStatus === 'open' ? 'close' : 'open';
    this.cardStatusChange.emit(this.cardStatus);
  }

  public openCard(): void {
    if (this.cardStatus === 'open') {
      return;
    }
    this.toggleState();
  }
}
