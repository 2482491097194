import { Injectable } from '@angular/core';
import { FutDialogService } from '../../../core/dialog-service/fut-dialog.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MaterialDialogInput } from '../model/material-dialog.model';
import { MaterialDialogComponent } from '../component/material-dialog/material-dialog.component';
import { isMobile } from '@futura/futura-ui/breakpoint';

@Injectable()
export class MaterialDialogService {
  constructor(
    private dialog: FutDialogService,
    private bottomSheet: MatBottomSheet
  ) {}

  public open(categoryPath: string): void {
    const dim = window.innerWidth;
    const data: MaterialDialogInput = { categoryPath };
    if (isMobile(dim)) {
      this.bottomSheet.open(MaterialDialogComponent, { data, panelClass: 'fut-bottom-sheet' });
    } else {
      this.dialog.open(MaterialDialogComponent, { dimension: 'md', matConf: { data } });
    }
  }
}
