<div
  [futTooltip]="tooltip"
  [hideTooltip]="hideTooltip"
  [style.--bar-color]="colorBar"
  class="bar-container d-flex d-flex align-items-center justify-content-center">
  @if (!hideIcon && (iconBar || forceIconPresence)) {
    <div class="d-flex mr-1">
      @if (state === 'NONE' || !state) {
        <mat-icon [fontSet]="fontSet">{{ iconBar }}</mat-icon>
      } @else {
        <mat-icon></mat-icon>
      }
    </div>
  }
  <div
    [ngClass]="{ 'fut-skeleton': state && state !== 'NONE', ghost: state === 'GHOST' }"
    [style.--bar-min-width.%]="minPercentage"
    [style.--bar-width.%]="percentage"
    [style.--bg-color]="colorBgBar"
    class="bar-chart"></div>
</div>
<ng-template #tooltip> {{ percentage || 0 | number: '1.0-0' }}%</ng-template>
