import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-material-bar',
  templateUrl: './material-bar.component.html',
  styleUrls: ['./material-bar.component.scss'],
})
export class MaterialBarComponent implements OnChanges {
  @Input() public percentage?: number;
  @Input() public state: ComponentState = COMPONENT_STATE.LOADING;
  public color = 'var(--fut-red-400)';
  @Input() public bgColor = 'var(--fut-secondary-200)';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.percentage) {
      if (this.percentage && this.percentage >= 20) {
        this.color = 'var(--fut-secondary)';
      } else {
        this.color = 'var(--fut-red-400)';
      }
    }
  }
}
